@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Raleway:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #6168db;
  --secondary-color: #61aa37;
  --white-color: #ffffff;

  --content-width: 1110px;
}
/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: #3E4852;
}
p {
  margin: 15px 0;
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
}

a{
  color: #3E4852;
}
/**
* Components
*/
.btn {
  cursor: pointer;
  display: inline-block;
  padding: 14px 30px 15px;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 700;
  text-align: center;
}

.btn--primary {
  background: #61aa37;
  background: var(--secondary-color);
  color: #ffffff;
  color: var(--white-color);
  border: 2px solid #61aa37;
  border: 2px solid var(--secondary-color);
}
.btn--outlined {
  background: #ffffff;
  background: var(--white-color);
  border: 2px solid #61aa37;
  border: 2px solid var(--secondary-color);
  color: #61aa37;
  color: var(--secondary-color);
}

.content {
  width: 100%;
  max-width: 1110px;
  max-width: var(--content-width);
  padding: 0 18px;
  margin: 0 auto;
}

@media screen and (min-width: 501px) {
  .content {
    width: 100%;
    max-width: 1110px;
    max-width: var(--content-width);
    margin: 0 auto;
    padding: 0;
    padding: 0 18px;
  }
}

.btn {
  padding: 14px 52px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  width: 100%;
}

@media screen and (min-width: 600px) {
  .btn {
    width: auto;
    padding: 18px 52px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
  }
}

.btn--primary {
  background: #6168db;
  background: var(--primary-color);
  color: #ffffff;
  color: var(--white-color);
  border-color: #6168db;
  border-color: var(--primary-color);
}

.header {
  width: 100%;
  background: url(https://tog-rappi-suhai-landing.herokuapp.com/static/media/bg-header.cd101c81.jpg) top center no-repeat;
  background-size: cover;
  text-align: left;
  color: #ffffff;
  color: var(--white-color);
  padding: 22px 0 122px;
}
.header__logo {
  max-width: 100px;
  width: 100%;
  margin: 0 auto 72px;
  display: block;
}
.header__title {
  max-width: 320px;
  font-size: 25px;
  font-weight: 700;
  line-height: 33px;
  color: #ffffff;
  color: var(--white-color);
  margin: 0 0 21px;
}

/* Desktop */
@media screen and (min-width: 600px) {
  .header {
    width: 100%;
    background: url(https://tog-rappi-suhai-landing.herokuapp.com/static/media/bg-header.cd101c81.jpg) top center no-repeat;
    background-size: cover;
    text-align: left;
    color: #ffffff;
    color: var(--white-color);
    padding: 142px 0 160px;
  }
  .header__logo {
    max-width: 179px;
    margin: 0 0 57px;
  
  }
  .header__title {
    max-width: 709px;;
    font-size: 48px;
    font-weight: 700;
    line-height: 60px;
    color: #ffffff;
    color: var(--white-color);
    margin: 0 0 21px;
  }
}


.header__text {
  margin: 0 0 56px;
}

.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 746.9px;
  margin: -48px auto 34px;
  border-radius: 4px;
  background: #F9F9F9;
  overflow: hidden;
}

.video-wrapper iframe { 
  position: relative;
  width: 100%;
}

.video-placeholder {
  /* position: absolute; */
  /* top: 0; */
  /* left: 0; */
  width: 100%;
  display: block;
  /* object-fit: cover; */
  /* height: 100%; */
  
}

@media screen and (min-width: 600px) {
  .video-wrapper {
    margin: 0 auto 60px;
  }
}

.overview {
  
}

.overview a {
  display: table;
  margin: 0 auto;
}

.overview__text {
  max-width: 979px;
  margin: 0 auto 34px;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  line-height: 32px;
  color: #333333;
}

@media screen and (min-width: 600px) {
  .overview__text {
    font-size: 21px;
    line-height: 32px;
    margin: 0 auto 60px;
  }
}

.section-coverage {
  background: #F9F9F9;
  padding: 43px 0;
}

.section-coverage__title {
  font-size: 21px;
  margin: 0 0 52px;
  text-align: center;
}
@media screen and (min-width: 600px) {
  .section-coverage__title {
    font-size: 42px;
    margin: 0 0 52px;
  }
}

.section-coverage .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.overview {
  padding: 0 0 50px;
}
@media screen and (min-width: 600px) {
  .overview {
    padding: 70px 0 68px;
  }
  .section-coverage {
    background: #F9F9F9;
    padding: 72px 0;
  }
}

.productimage {
  width: 100%;
  max-width: 1110px;
  max-width: var(--content-width);
  margin: 0 auto 37px;
  display: block;
}

.product__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 24px;
}
.product__icon img {
  margin-right: 18px;
}

.productname {
  text-align: center;
}

.productname h3 {
  font-weight: 600;
}

.product__video {
  width: 100%;
  margin: 0;
  padding: 0;
}

.product__controls {
  display: flex;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  flex-direction: column;
  margin: 36px 0;
}

@media screen and (min-width: 501px) {
  .product__controls {
    flex-direction: row;;
  }
}
.coverage {
  padding: 30px 18px;
  background: #FFFFFF;
  box-shadow: 2px 3px 8px rgba(0, 0, 0, 0.06);
  border-radius: 2px;
  max-width: 526px;
  width: 100%;
  margin: 0 auto 64px;
}
@media screen and (min-width: 600px) {
  .coverage {
    padding: 30px 38px;
  }
}
.coverage .btn {
  width: 100%;
  max-width: 100%;;
  display: block;
  margin: 28px 0 0;
}

.coverage__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.coverage__title h4 {
  font-family: 'Nunito', sans-serif;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 600px) {
  .coverage__title h4 {
    font-size: 32px;
  }
}

.coverage__separator {
  width: 100%;
  height: 2px;
  background: #ddd;
  margin: 23px 0;
}
.coverage__table {
  width: 100%;
  font-size: 20px;

}
.coverage__table tr td {
  padding: 4.5px 0;
  font-size: 14px;
  font-family: 'Nunito', sans-serif;
}

@media screen and (min-width: 600px) {
  .coverage__table tr td {
    font-size: 20px;
  }
}

.coverage__table tr td:first-of-type {
  display: table-cell;
  align-items: center;
}
.coverage__table tr td:first-of-type img {
  margin-left: 5px;
}
.coverage__table tr td:first-of-type {
  font-weight: 700;
}
.coverage__table tr td:last-of-type {
  text-align: right;
}

.footer {
  width: 100%;
  padding: 40px 0 30px;
} 
.footer .content {
  display: flex;
  justify-content: center;
}
.footer__copyright {
  display: flex;
  align-items: center;
}
.footer__copyright img {
  margin-left: 10px;
}

.accordion {
  border: none;
  -webkit-filter: drop-shadow(2px 3px 8px rgba(0, 0, 0, 0.06));
          filter: drop-shadow(2px 3px 8px rgba(0, 0, 0, 0.06));
  margin-bottom: 60px;
  max-width: 526px;
  width: 100%;
  margin: 0 auto 60px;
}
.accordion__item {
  background: #F3F2F2;
  border: 1px solid #DDDDDD;
}
.accordion__button {
  background: #F3F2F2;
  border: 1px solid #DDDDDD;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
  border-radius: 2px;
  color: #000;
  font-weight: 400;
  margin-top: -2px;
  font-family: 'Nunito', sans-serif;
}
.accordion__button {

}
.accordion__panel {
  background: #fff;
}

table td {
  display: table-cell;
}

.coverage_info {
  font-family: 'Nunito', sans-serif;
  max-width: 521px;
  text-align: center;
  color: #6F6967;
  margin: 60px 0 0;
}
